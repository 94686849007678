/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 11:26:28
 * @Module: 段落展开收起
 */
 <template>
  <div>
    <span v-if="isCut">
      <span>{{text|textCut(this)}}</span>
      <span style="color:#8BAADE"
            class="hover"
            @click.stop="click "
            v-show="textShow">全文</span>
    </span>
    <span v-else>
      <span>{{text}}</span>
      <span style="color:#8BAADE"
            class="hover"
            @click.stop="isCut=true">收起</span>
    </span>
    <!-- <span>
      <span style="color:#8BAADE"
            class="hover"
            v-show="textShow">全文</span>
    </span> -->
  </div>
</template>
 <script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    cut: {
      type: Number,
      default: 100
    },
    canOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isCut: true,   //是否展开
    };
  },
  mounted () { },
  computed: {
    textShow () {
      if (this.text) {
        return this.text.length > this.cut
      } else {
        return false
      }
    },

  },
  methods: {
    click () {
      if (this.canOpen) {
        this.isCut = false
      }
    }
  },
  filters: {
    textCut (text = " ", that) {
      if (text)
        return text.substring(0, that.cut) + (text.length > that.cut ? '...' : '')
      else
        return text
    }
  }
};
 </script>
 <style lang='scss' scoped>
</style>