<template>
  <div :style="'height:'+fullHeight+'px;'"
       class="lx-content-style">
    <div class="lx-content-text"
         style="">
      <!-- <div style="color:rgba(255,255,255,.3)">corporate name</div> -->
      <div style="margin-top:.38rem;font-size:.44rem">中能魔力（河南）网络科技有限公司</div>
      <div style="margin-top:.6rem">地址：洛阳市万达世纪中心21层</div>
      <div style="margin-top:.62rem;display:flex;">
        <div style="">
          <div>电话：400-119-3009</div>
          <div style="margin-top:.6rem;">邮编：471000</div>
        </div>
        <div style="display:flex;margin-left:.58rem">
          <img src="@/static/wanghong/kefu.jpg"
               style="height:1.4rem;width:1.4rem;"
               alt="">
          <div style="margin-left:.32rem;display: flex;flex-direction: column;justify-content: space-around;">
            <div>扫码二维码</div>
            <div>添加客服人员</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LXContent",
  data () {
    return {
      fullHeight: 0,
    }
  },
  mounted () {
    this.fullHeight = document.documentElement.clientHeight
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.lx-content-style {
  background: url("https://oss.mcn-open.com/video/bda18b990eea9c32e83b0898cb7c8d6.png")
    no-repeat;
  background-size: cover;
  position: relative;

  .lx-content-text {
    width: 10.48rem;
    height: 5.48rem;
    position: absolute;
    right: 0px;
    bottom: 0px;
    // border-top 1px solid #fff
    // border-left 1px solid #fff
    box-sizing: border-box;
    padding: 0.78rem 0.8rem;
    color: #ffffff;
  }
}
</style>