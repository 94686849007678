/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-17 11:05:24
 * @Module: 右侧轮播图广告
 */
 <template>
  <div class="carousel-box"
       v-loading="loading"
       v-show="carouselShow">
    <el-carousel height="272px"
                 arrow="never"
                 indicator-position="none">
      <el-carousel-item v-for="(item,index) in list"
                        :key="index">
        <el-image style="width: 100%; height: 100%"
                  :src="item.image"
                  @click="click(item)"
                  :class="{'hover':item.link}"
                  fit="cover"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="carousel-box-adIcon">广告</div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      loading: true,
      list: [],
      carouselShow: true
    };
  },
  mounted () {
    this._get_list()
  },
  methods: {
    click ({ link }) {
      window.open(link, "_blank")
    },
    async _get_list () {
      this.loading = true
      const { status, info, data } = await this.$api.getHomeBanners()
      this.loading = false
      if (status == 200) {
        this.list = [...data, ...data]
        this.carouselShow = data.length !== 0
      } else {
        this.$message.error(info)
        this.carouselShow = false
      }
    }
  }
}
 </script>
 <style lang='scss' scoped>
.carousel-box {
  margin-top: 16px;
  height: 272px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  width: 340px;
  &-adIcon {
    width: 47px;
    line-height: 25px;
    background: #000000;
    border-radius: 6px;
    opacity: 0.6;
    border: 1px solid #ffffff;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    position: absolute;
    right: 7px;
    bottom: 7px;
    z-index: 2;
  }
}
</style>
