<template>
  <div class="wrapper">
    <a href="javascript:void(0)"
       class="left">
      <img src="@/static/wanghong/pc-logo.png"
           alt="网红头条logo" />
    </a>
    <ul class="right">
      <router-link to="/"
                   :exact="true">
        <li class="item">
          <span class="itemText">首页</span>
        </li>
      </router-link>
      <router-link to="/laboratory"
                   :exact="true">
        <li class="item">
          <span class="itemText">实验室</span>
        </li>
      </router-link>

      <router-link to="/certification"
                   :exact="true">
        <li class="item">
          <span class="itemText">身份查询</span>
        </li>
      </router-link>
      <!-- <router-link to="/musician">
				<li class="item"><a href="">抖音音乐人</a></li>
      </router-link>-->
      <router-link to="/about"
                   :exact="true">
        <li class="item">
          <span class="itemText">关于我们</span>
        </li>
      </router-link>
      <!-- <li class="item"><a href="">企业认证</a></li> -->
      <!-- 静音 -->
      <li class="item slience"
          :style="opacity">
        <a href="javascript:void(0)"
           @click="handleSlience()">
          <span class="iconfont">&#xe607;</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
  data () {
    return {
      opacity: {
        opacity: 1
      }
    };
  },
  methods: {
    handleSlience () {
      this.opacity.opacity == 1
        ? (this.opacity.opacity = .4)
        : (this.opacity.opacity = 1);
      this.$emit("slience", this.opacity.opacity);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  position: absolute;
  top: 0;
  // top .4rem
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 21rem;
  height: 1.4rem;

  .left {
    margin-left: 2rem;
  }

  .right {
    margin-right: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.35rem;
    color: #dbdbdb;

    .item {
      position: relative;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      float: left;
      padding: 0 0.25rem;
      color: rgba(255, 255, 255, 1);

      .beta {
        font-size: 0.8rem;
        line-height: 0.8rem;
        margin-left: -0.4rem;
      }
    }

    .slience .iconfont {
      font-size: 0.6rem;
    }

    .action-on,
    .router-link-active {
      .itemText {
        cursor: pointer;
        color: #ffffff;
      }
    }

    .iconfont {
      color: rgba(255, 255, 255, 1);
    }

    .router-link-active > .item::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 3px;
      z-index: 1000;
      bottom: -10px;
      background-color: #df2a29;
      border-radius: 2px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .item:hover .itemText:after {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    .itemText:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 3px;
      z-index: 1000;
      bottom: -10px;
      background-color: #df2a29;
      border-radius: 2px;
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
      -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
      transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out,
        -webkit-transform 0.2s ease-out;
      -webkit-transform-origin: 50% 0;
      transform-origin: 50% 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
  }
}
</style>
