<template>
  <div>
    <div class="title">
      星探排行榜
      <div class="block">
        <el-date-picker v-model="value2" type="month" placeholder="月份">
        </el-date-picker>
      </div>
    </div>

    <div class="all">
      <div class="sort s1">
        <div class="sone">
          <div class="top top1" v-for="(item, i) in List1" :key="item.id">
            <div
              v-show="item.src"
              style="text-align: center;width: 40px;height: 34px;display:flex;align-items:center;justify-content:center;"
            >
              <img :src="item.src" style="width: 22px;height: 19px;" />
            </div>
            <div
              v-show="!item.src"
              style="text-align: center;width: 40px;height: 34px;color:#999;line-height:34px;"
            >
              <span>{{ i | numFilter1 }}</span>
            </div>
            <div class="Img">
              <img :src="item.img" />
            </div>
            <div class="txt">
              <p>{{ item.name }}</p>
              <span>{{ item.pro }}</span>
            </div>
            <div class="sy1">
              <p>总收益</p>
              <span>￥{{ item.sy }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sort">
        <div class="sone">
          <div class="top top1" v-for="(item, i) in List1" :key="item.id">
            <div
              v-show="item.src"
              style="width: 40px;height: 34px;display:flex;align-items:center;justify-content:center;"
            >
              <img :src="item.src" style="width: 22px;height: 19px;" />
            </div>
            <div
              v-show="!item.src"
              style="text-align: center;width: 40px;height: 34px;color:#999;line-height:34px;"
            >
              <span>{{ i | numFilter1 }}</span>
            </div>
            <div class="Img">
              <img :src="item.img" />
            </div>
            <div class="txt">
              <p>{{ item.name }}</p>
              <span>{{ item.pro }}</span>
            </div>
            <div class="sy1">
              <p>总人数</p>
              <span style="color:#2990F9">{{ item.per }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import first from "../../../static/starImg/first.png";
import second from "../../../static/starImg/second.png";
import third from "../../../static/starImg/third.png";
export default {
  data() {
    return {
      value2:'',
      firstImg: [],
      List1: [
        {
          img:
            "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=25462226,492036088&fm=26&gp=0.jpg",
          name: "张小万",
          pro: "这是我的个人介绍",
          sy: "1236.67",
          per: 10
        },
        {
          img:
            "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=25462226,492036088&fm=26&gp=0.jpg",
          name: "张小万",
          pro: "这是我的个人介绍",
          sy: "1236.67",
          per: 20
        },
        {
          img:
            "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=25462226,492036088&fm=26&gp=0.jpg",
          name: "张小万",
          pro: "这是我的个人介绍",
          sy: "1236.67",
          per: 12
        },
        {
          img:
            "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=25462226,492036088&fm=26&gp=0.jpg",
          name: "张小万",
          pro: "这是我的个人介绍这是我的个人介绍",
          sy: "1236.67",
          per: 12
        },
        {
          img:
            "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=25462226,492036088&fm=26&gp=0.jpg",
          name: "张小万",
          pro: "这是我的个人介绍",
          sy: "1236.67",
          per: 12
        }
      ]
    };
  },
  filters: {
    numFilter1(num) {
      return num < 9 ? "0" + (num + 1) : num + 1;
    }
  },
  created() {
    this.firstImg.push(first, second, third);
    this.List1.forEach((item, index) => {
      if (index == 0) {
        this.$set(item, "src", this.firstImg[0]);
      } else if (index == 1) {
        this.$set(item, "src", this.firstImg[1]);
      } else if (index == 2) {
        this.$set(item, "src", this.firstImg[2]);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 60px;
  line-height: 60px;
  margin-left: 10px;
  display: flex;
  position: relative;
  .block{
    position: absolute;
    right: 3px;
  }
}
.all {
  display: flex;

  .s1 {
    margin-right: 16px;
  }
  .sort {
    width: 49%;
    background: #fff;
    border-radius: 4px;
    .top:nth-of-type(odd) {
      background: #fff;
    }
    .top:nth-of-type(even) {
      background: #fafafa;
    }
    .top {
      height: 82px;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1px solid #eee;
      .Img {
        width: 58px;
        height: 58px;
        background-color: #fff;
        margin-left: 9px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .txt {
        width: 45%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        p {
          font-size: 15px;
          font-weight: 500;
          color: #333333;
        }
        span {
          font-size: 13px;
          color: #666666;
        }
      }
      .sy1 {
        width: 20%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: right;
        position: absolute;
        right: 4%;
        p {
          font-size: 13px;
          color: #666666;
        }
        span {
          font-size: 15px;
          font-weight: 500;
          color: #df2a29;
        }
      }
    }
  }
}
/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 116px;
}
</style>