<template>
  <div class="platform">
    <!-- <div class="outerCircle "></div> -->
    <img src="@/static/img/home/PlatformDataBg.png"
         class="bg-img"
         alt=""
         srcset="" />
    <div class="imgBox">
      <div class="tiktokBox">
        <img class="tiktokImg"
             src="@/static/img/home/Tiktok.png"
             alt="" />
        <div class="tiktokData">
          <div class="triangleBox"></div>
          <div class="tiktokLeftData platformFlex">
            <div class="platformNum">10000+</div>
            <div class="platformTitle">签约主播</div>
          </div>
          <div class="tiktokRightData platformFlex">
            <div class="platformNum">2亿+</div>
            <div class="platformTitle">收益流水</div>
          </div>
        </div>
      </div>
      <div class="tantanBox">
        <img class="tantanImg"
             src="@/static/img/home/Tantan.png"
             alt="" />
        <div class="tantanData DataBoxFlex">
          <div class="tantantriangleBox"></div>
          <div class="tantanPlatformFlex">
            <div class="tiktokLeftData tantanLeftData platformFlex">
              <div class="platformNum">10000+</div>
              <div class="platformTitle">签约主播</div>
            </div>
            <div class="tiktokRightData tantanRightData platformFlex">
              <div class="platformNum">1亿+</div>
              <div class="platformTitle">收益流水</div>
            </div>
          </div>
        </div>
      </div>
      <div class="kwaiFuBox">
        <img class="kwaiFuImg"
             src="@/static/img/home/KwaiFu.png"
             alt="" />
        <div class="kwaiFuData DataBoxFlex">
          <div class="kwaiFutriangleBox"></div>
          <div class="tantanPlatformFlex">
            <div class="tiktokLeftData kwaiFuLeftData platformFlex">
              <div class="platformNum">7000+</div>
              <div class="platformTitle">签约主播</div>
            </div>
            <div class="tiktokRightData tantanRightData platformFlex">
              <div class="platformNum">2亿+</div>
              <div class="platformTitle">收益流水</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "platform",
  data () {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.platform {
  // height: 650px;
  width: 100%;
  // background-image: url("../../../@/static/img/home/PlatformDataBg.png");
  // background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  .bg-img {
    width: 100%;
    // position: absolute;
    z-index: 3;
  }
  .imgBox {
    z-index: 2;
  }
}
.tiktokBox {
  position: absolute;
  top: 22%;
  left: 16%;
}
.tantanBox {
  position: absolute;
  top: 37%;
  left: 52.5%;
}
.tantanImg {
  width: 100%;
}
.kwaiFuBox {
  position: absolute;
  top: 27%;
  left: 75.2%;
}
.kwaiFuImg {
  width: 100%;
}
.tiktokData {
  width: 238px;
  height: 79px;
  position: absolute;
  top: -39px;
  left: 106px;
  display: flex;
  flex-direction: row;
}
.triangleBox {
  border-bottom: 10px solid rgba(0, 0, 0, 0.5);
  border-left: 4px solid transparent;
  width: 0px;
  height: 88%;
}
.tiktokLeftData {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 50%;
  border-radius: 4px 0 0 0;
}
.tiktokRightData {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 50%;
  border-radius: 0 4px 4px 0;
}
.tiktokLeftData::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 25px;
  border-radius: 1px;
  opacity: 0.5;
  z-index: 1000;
  /* bottom: -13px; */
  background-color: #ffffff;
  right: 48%;
}
.platformFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.platformNum {
  font-weight: 500;
  color: #ffffff;
  font-size: 21px;
  margin-bottom: 8px;
}
.platformTitle {
  font-weight: 400;
  color: #509ded;
  font-size: 15px;
}
.tantanData {
  width: 238px;
  height: 90px;
  right: 7px;
  position: absolute;
  bottom: -81px;
}
.DataBoxFlex {
  display: flex;
  flex-direction: column;
}
.tantanPlatformFlex {
  display: flex;
  height: 100%;
}
.tantantriangleBox {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid rgba(0, 0, 0, 0.5);
  margin-left: 76%;
}
.tantanLeftData {
  border-radius: 4px 0 0 4px;
}
.tantanLeftData::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 25px;
  border-radius: 1px;
  opacity: 0.5;
  z-index: 1000;
  /* bottom: -13px; */
  background-color: #ffffff;
  right: 49%;
}
.kwaiFuData {
  width: 238px;
  height: 90px;
  left: 34px;
  position: absolute;
  bottom: -81px;
}
.kwaiFutriangleBox {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid rgba(0, 0, 0, 0.5);
  margin-left: 9%;
}
.kwaiFuLeftData {
  border-radius: 4px 0 0 4px;
}
</style>
