<template>
  <div class="all">
    <div v-for="(item, inx) in List" :key="inx">
      <div
        class="wrap"
        v-on:mouseover="changeActive(inx)"
        v-on:mouseout="removeActive()"
      >
        <span v-if="show && inx == current">{{ item.name }}</span>
        <img v-else :src="item.img" />
      </div>
    </div>
    <img
      src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1603960404968&di=08e41bbe943f5d2d93d37391287fe2ca&imgtype=0&src=http%3A%2F%2Fa2.att.hudong.com%2F36%2F48%2F19300001357258133412489354717.jpg"
      alt=""
      class="Img"
      v-if="show && current == 3"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      current: 0,
      List: [
        {
          img: require("../../static/img/top.png"),
          name: "回顶部"
        },
        {
          img: require("../../static/img/collect.png"),
          name: "收藏"
        },
        {
          img: require("../../static/img/zf.png"),
          name: "转发"
        },
        {
          img: require("../../static/img/wechat.png"),
          name: "微信"
        },
        {
          img: require("../../static/img/pl.png"),
          name: "评论"
        }
      ]
    };
  },
  methods: {
    changeActive(inx) {
      console.log(inx);
      this.show = true;
      this.current = inx;
      console.log(this.current);
    },
    removeActive() {
      this.show = false;
      this.current = null;
    }
  }
};
</script>

<style lang="less" scoped>
.all {
  width: 50px;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  position: relative;

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 24px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    font-size: 14px;
    color: #474747;
    span {
      font-size: 14px;
    }
  }
  .Img {
    position: absolute;
    top: 55%;
    left: -120px;
    width: 102px;
    height: 102px;
  }
}
</style>