/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-09 16:26:25
 * @Module: 微信按钮
 */
 <template>
  <div class="phone-box"
       @mouseenter="showTooltip=true"
       @mouseleave="showTooltip=false">
    <i class="iconfont iconweixin"></i>
    <div class="border"></div>
    <div class="phone-tooltip"
         v-show="showTooltip">
      <img src="@/static/organ/adminCode.png"
           class="phone-tooltip-code" />
      <div class="kailong"></div>
    </div>
  </div>
</template>
 <script>
export default {
  data () {
    return {
      showTooltip: false,
    };
  },
  components: {},
  mounted () { },
  methods: {},
};
</script>
<style lang="scss" scoped>
.phone-tooltip {
  width: 134px;
  height: 130px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  left: -150px;
  top: 0;
  font-size: 14px;
  line-height: 35px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  &-code {
    width: 88px;
    height: 88px;
    background: #eee;
  }
  .kailong {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #fff;
    position: absolute;
    right: -8px;
    top: 20px;
  }
}
.phone-box {
  z-index: 9999;
  cursor: pointer;
  width: 60px;
  height: 65px;
  position: fixed;
  right: 18px;
  text-align: center;
  bottom: 148px;
  transition: background 0.7s;
  background-color: rgba(91, 121, 154, 0.5);
  &:hover {
    background-color: rgba(91, 121, 154, 1);
  }
  i {
    color: #fff;
    font-size: 26px;
    line-height: 65px;
  }
  .border {
    width: 52px;
    height: 1px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: -1px;
    opacity: 0.6;
  }
}
</style>