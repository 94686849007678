<template>
  <div>
    <el-button type="text" @click="dialogVisible = true"
      >点击打开 Dialog</el-button
    >
    <el-dialog :visible.sync="dialogVisible" width="48%" title="转发">
      <div class="top">
        <div class="timg">
          <img
            src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1604054477374&di=9ee5d453e6d0eb103d38fa92de870a37&imgtype=0&src=http%3A%2F%2Fa2.att.hudong.com%2F36%2F48%2F19300001357258133412489354717.jpg"
            mode="aspectFill"
          />
        </div>
        <div class="content">
          <p>
            <span>@央视新闻：</span
            >习近平总书记习近平总书记习近习近平总书记习近平总书记习近习近平总书记习近平总书记习近
          </p>
        </div>
      </div>
      <div class="txtarea">
        <textarea
          style="resize:none;"
          placeholder="请输入内容"
          placeholder-style="padding:12px"
          maxlength="200"
          @input="descInput"
          v-model="desc"
        >
        </textarea>
        <div class="bot">{{ txtVal }}/200字</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" @click="dialogVisible = false">转发</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      txtVal: 0,
      desc: ""
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    descInput() {
      this.txtVal = this.desc.length;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 15px;
}
/deep/ .el-dialog__header {
  padding: 0;
  height: 50px;
  line-height: 50px;
  text-indent: 10px;
  font-size: 16px;
  // font-weight: 500;
}
/deep/ .el-dialog__body {
  background: #f7f7f7;
  height: 332px;
}
/deep/ .el-dialog__footer {
  padding: 0 20px;
  line-height: 70px;
}
.top {
  height: 122px;
  background: #e3e3e3;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  .timg {
    width: 290px;
    height: 102px;
    border-radius: 4px;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .content {
    line-height: 20px;
  }
}
.txtarea {
  height: 140px;
  margin-top: 10px;
  background: #fff;
  position: relative;
  border-radius: 4px;
  padding: 12px;
  textarea {
    width: 100%;
  }
  .bot {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #000;
    opacity: 0.5;
    border-radius: 9px;
    width: 70px;
    height: 18px;
    text-align: center;
    line-height: 18px;
  }
}
.btn {
  background: #df2a29;
  font-size: 14px;
  width: 120px;
  height: 40px;
  color: #fff;
}
</style>