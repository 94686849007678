/* * @Author: 曹俊杰 * @Date: 2020-09-09 10:47:28 * @Module: 查询结果 */
<template>
  <div class="certification"
       style="background:#fff;">
    <resultheader search />
    <div class="certification-content certification-result">
      <div class="certification-result-content">
        <div class="certification-result-content-item"
             v-if="data">
          <div class="certification-result-content-item-l">
            <img class="certification-result-content-item-l-innerRing"
                 :style=" loading ? styleObjectfast: styleObject"
                 src="@/static/staffimg/InnerRing.png"
                 alt />
            <img class="certification-result-content-item-l-outerRing"
                 :style=" loading ? styleObjectfast: styleObject"
                 src="@/static/staffimg/OuterRing.png"
                 alt />
            <img class="certification-result-content-item-l-pimg"
                 :src="require(`../../${data.img}`)"
                 alt
                 srcset />
          </div>
          <div class="certification-result-content-item-r">
            <div class="certification-result-content-item-r-identity">
              IDENTITY
            </div>
            <div class="certification-result-content-item-r-title">
              {{ data.name }}
            </div>
            <div class="certification-result-content-item-r-text">
              网红头条官方认证人员
            </div>
            <img src="@/static/certificationImg/icon_2.png"
                 style="width:342px;"
                 alt
                 srcset />
            <div>
              <span class="certification-result-content-item-r-t">微信：</span>
              <span class="certification-result-content-item-r-n">{{
                data.wechart
              }}</span>
            </div>
            <div>
              <span class="certification-result-content-item-r-t">电话：</span>
              <span class="certification-result-content-item-r-n">{{
                data.phone
              }}</span>
            </div>
            <div>
              <span class="certification-result-content-item-r-t">邮箱：</span>
              <span class="certification-result-content-item-r-n">{{
                data.email
              }}</span>
            </div>
          </div>
        </div>
        <div v-else
             class="certification-result-content-item"
             style="justify-content: center;">
          <div style="color:#333;">
            <span>抱歉，没有找{{ type == "phone" ? "电话" : "微信" }}为“</span>
            <span style="color:#DF2A29">{{ keyWord }}</span>
            <span>”的网红头条工作人员</span>
          </div>
        </div>
        <img src="@/static/certificationImg/text_2.png"
             class="certification-result-content-seal"
             v-if="data" />
        <div class="certification-result-content-back pointer"
             @click="$router.go(-1)">
          <i class="iconfont iconfanhui"></i>
          <i>返回</i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resultheader from "./header";
import list from "./staff.list";
export default {
  data () {
    return {
      type: null,
      keyWord: null,
      data: null,
      loading: true,
      styleObjectfast: {
        animationDuration: '2s',
        // animationIterationCount: '1',
        animationTimingFunction: 'cubic-bezier(0,.83,.45,.92)',
        animationIterationCount: 'infinite'
      },
      styleObject: {
        animationDuration: '8s',
        // animationIterationCount: '1',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        animationDelay: '2s'
      }
    };
  },
  components: {
    resultheader
  },
  created () {
    const { type, keyWord } = this.$router.history.current.query;
    this.type = type;
    this.keyWord = keyWord;
    if (type && keyWord) {
      list.forEach((item) => {
        if (item[type] == keyWord) {
          this.data = item;
        }
      });
    }

  },
  mounted () {
    setTimeout(() => (this.loading = false), 2000);
  },
  methods: {},
  filters: {
    imgurl (url) {
      return require('@' + url)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
.certification-result {
  &-content {
    width: 960px;
    height: 540px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &-back {
      position: absolute;
      left: 30px;
      bottom: 24px;
      color: #999;
    }
    &-seal {
      width: 300px;
      position: absolute;
      top: 10px;
      right: 20px;
    }
    &-item {
      width: 657px;
      height: 255px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-l {
        width: 255px;
        height: 255px;
        border-radius: 255px;
        position: relative;
        &-innerRing {
          position: absolute;
          width: 100%;
          // transform: translate(-50%, -50%);
          animation-name: turn;

          // animation: turn 8s linear infinite;
          // animation-iteration-count:infinite
        }

        &-outerRing {
          position: absolute;
          width: 237px;
          // animation: turn2 8s linear infinite;
          animation-name: turn2;
          animation-iteration-count: infinite;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
        &-pimg {
          position: absolute;
          width: 213px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &-r {
        width: 342px;
        height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        &-identity {
          position: absolute;
          font-size: 22px;
          color: #333333;
          opacity: 0.1;
          line-height: 33px;
          letter-spacing: 5px;
          top: -16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
        }
        &-title {
          font-size: 22px;
          color: #4b9cfc;
          font-weight: 500;
        }
        &-text {
          font-size: 20px;
          color: #4b9cfc;
          font-weight: 500;
        }
        &-t,
        &-n {
          font-size: 16px;
        }
        &-t {
          color: #666666;
        }
        &-n {
          color: #333;
        }
      }
    }
  }
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  // 25% {
  //   -webkit-transform: rotate(90deg);
  // }
  // 50% {
  //   -webkit-transform: rotate(180deg);
  // }
  // 75% {
  //   -webkit-transform: rotate(270deg);
  // }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes turn2 {
  0% {
    -webkit-transform: rotate(360deg);
  }
  // 25% {
  //   -webkit-transform: rotate(270deg);
  // }
  // 50% {
  //   -webkit-transform: rotate(180deg);
  // }
  // 75% {
  //   -webkit-transform: rotate(90deg);
  // }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
</style>
