/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-14 14:19:51
 * @Module: 发布，关注，粉丝 通知的轮询
 */
// let that = null

// // 查询是否有新通知
// const hasNew = async () => {
// 	const { status, data } = await that.$api.newNoticeApi()
// 	setTimeout(hasNew, 60000);
// 	console.log(data, status)
// 	// if (status == 200) {
// 	// }
// 	// else {
// 	// 	console.log(data)
// 	// 	hasNew()
// 	// }
// }
// 初始化轮询
const intPolling = async () => {
	// that = _this
	// hasNew()
}
export {
	intPolling
}