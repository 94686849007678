/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-31 11:43:19
 * @Module: 首页和星探的layouts
 */
/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-29 14:59:03
 * @Module: 首页
 */
 <template>
  <div class="page">
    <el-container class="page-content">
      <el-main width="884px"
               class="page-content-l"
               style="padding:0px;">
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-main>
      <el-aside width="340px"
                class="page-content-r">
        <loginmodule />
        <topup />
        <attention />
      </el-aside>
    </el-container>
  </div>
</template>
 <script>
import loginmodule from "@/views/login/loginmodule"
import topup from "@/views/homepage/rightmodule/topup"
import attention from "@/views/homepage/rightmodule/attention"
export default {
  components: {
    loginmodule,
    topup,
    attention
  },
  data () {
    return {};
  },
  created () { },
  methods: {},
  computed: {}, //计算属性
};
 </script>
 <style lang='scss' scoped>
.page {
  background: #f7f7f7;
  min-height: 100vh;
  border: solid 1px #f7f7f7;
}
.page-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 68px;
  &-l {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  }
  &-r {
    margin-left: 16px;
  }
}
</style>